import React from 'react';
import { graphql, PageProps } from 'gatsby';
import Seo from '../components/Seo';
import FooterCredit from '../components/FooterCredit';
import BlogArchive from '../components/Blog/Archive';
import GeneralHero from '../components/GeneralHero';
import { Description, PageTitle } from '../components/Typography';

export default function Template(
	props: PageProps<GatsbyTypes.AllBlogPostsQuery, {}>
) {
	const { data } = props;

	return (
		<>
			<Seo
				description={
					'Welcome WPEForm Blog. Here we write about WordPress, JavaScript, React, Gatsby, PHP, server, administration and lot more.'
				}
				title={`WPEForm Blog - Writing about JavaScript, React, WordPress and more - WordPress Form Builder`}
				featuredImage={data.blogFeaturedImage?.childImageSharp?.gatsbyImageData}
			/>
			<GeneralHero>
				<PageTitle className="centered" as="h2">
					Welcome to WPEForm Blog
				</PageTitle>
				<Description className="centered">
					Here we write about JavaScript, React, WordPress, PHP, GatsbyJS and
					more. We hope you find these articles useful. Do give us a shoutout on
					twitter!
				</Description>
			</GeneralHero>
			<BlogArchive data={data.allMdx} />
			<FooterCredit />
		</>
	);
}

export const query = graphql`
	query AllBlogPosts {
		allMdx(
			filter: {
				fileAbsolutePath: { glob: "**/site-data/blog/**/*.mdx" }
				frontmatter: { published: { eq: true } }
			}
			sort: { fields: frontmatter___date, order: DESC }
		) {
			nodes {
				id
				slug
				frontmatter {
					date
					heading
					featuredImage {
						childImageSharp {
							gatsbyImageData(
								layout: CONSTRAINED
								width: 850
								placeholder: TRACED_SVG
								aspectRatio: 2.0
							)
						}
					}
				}
				excerpt(pruneLength: 300, truncate: false)
			}
		}
		blogFeaturedImage: file(
			absolutePath: { glob: "**/src/images/blog-featured-image.jpg" }
		) {
			childImageSharp {
				gatsbyImageData(width: 1200, height: 627, layout: FIXED)
			}
		}
	}
`;
